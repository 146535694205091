<template>
  <EditModal ref="editAddressForm">
    <template #btn-text>
      edit
    </template>
    <template #formTitle>
      Edit Pension
    </template>
    <template #form>
      <Form
          :validation-schema="schema"
          class="w-100 text-left"
          @submit="saveForm"
      >
        <TextInput
            :value="policy_number"
            label="Policy number"
            name="policy_number"
            type="text"
        />
        <DatePicker :value="fromDate" label="From date" name="from_date"/>
        <DatePicker :value="toDate" label="To date" name="to_date"/>
        <TextArea
            :value="note?.content"
            label="Notes"
            name="note"
            type="text"
        />
        <button class="fabric-btn fabric-btn-submit">Save</button>
      </Form>
    </template>
  </EditModal>
</template>
<script>
import {mapGetters, mapMutations, mapActions} from "vuex";
import DatePicker from "@/components/form/DatePicker";
import * as Yup from "yup";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);

export default {
  name: "TracedPensionForm",
  props: {
    tracedPension: {
      default: null
    },
    aggregationReport: {
      default: null
    }
  },
  emits: ["updated"],
  data: () => ({
    from_date: null
  }),
  setup() {
    // const schema = Yup.object().shape({
      // pension_provider: Yup.string()
      //     .required("required")
      //     .nullable(),
      // from_date: Yup.string().nullable(),
      // to_date: Yup.string().nullable()
    // });
    // return {
    //   schema
    // };
  },
  components: {
    DatePicker
  },
  computed: {
    ...mapGetters(["getApplication"]),
    note() {
      return this.tracedPension?.note[0] || null;
    },
    fromDate() {
      let tempdate = this.parseDate(
          this.evalutateProperty("tracedPension?.from_date")
      );
      if (tempdate) {
        return tempdate;
      }
      return null;
    },
    toDate() {
      let tempdate = this.parseDate(
          this.evalutateProperty("tracedPension?.to_date")
      );
      if (tempdate) {
        return tempdate;
      }
      return null;
    }
  },
  methods: {
    ...mapMutations(["setNotification", "setLoading"]),
    ...mapActions(["updateTracedPension", "fetchProviderList"]),
    async saveForm(params) {
      console.log(params);
      this.setLoading({bool: true});
      let tempParams = {};
      let provider = {
        id: this.tracedPension.id,
        policy_number: params.policy_number,
        from_date: params.from_date
            ? dayjs(params.from_date).format("YYYY-MM-DD")
            : null,
        to_date: params.to_date
            ? dayjs(params.to_date).format("YYYY-MM-DD")
            : null,
        note: null,
        pushFinderMatch: false
      };

      let note = {
        id: this.note?.id || null,
        content: params.note || ""
      };
      provider.note = note;

      tempParams.pensions = [];

      tempParams.pensions.push({
        provider
      });

      let response = await this.updateTracedPension({tempParams}).catch(
          err => {
            this.setNotification({
              show: true,
              type: "error",
              message: "Pension trace error!",
              content: err.response.data.error
            });
            this.$refs.editAddressForm.show = false;
            this.setLoading({bool: false});
          }
      );
      if (response?.status == 200) {
        await this.fetchProviderList().catch(err => console.log(err));
        this.setNotification({
          show: true,
          type: "success",
          message: "Pension trace Updated!"
        });
      }
      this.$refs.editAddressForm.show = false;
      this.setLoading({bool: false});
    }
  },
  beforeMount() {
    this.from_date = this.tracedPension?.from_date;
  }
};
</script>
<style lang="scss" scoped></style>
