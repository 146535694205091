<template>
  <EditModal ref="finderKnowledgeBase" :class="['-vw70',addInline]" @opened="handleOpened">
    <template #btn-text>
      <span v-if="inline" class="btn btn-small btn-info pointer">Use</span>
      <span v-else>edit</span>
    </template>
    <template #formTitle>
      <span class="top">Finder Knowledge Base</span>
    </template>
    <template #form>
      <div class="row">
        <div class="col-12 mt-3">
          <Form
              :validation-schema="schema"
              class="w-100 text-left"
              @submit="saveForm"
          >
            <div class="row">
              <div class="col-6">
                <div class="row">
                  <div class="col-12">
                    <CheckInput
                        :checkedVal="externalSource"
                        :value="true"
                        label="Create new provider"
                        name="source"
                        type="radio"
                        @updated="handleCreateNew($event,'create_new_source')"
                    />
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <CheckInput
                        :checkedVal="externalSource"
                        :value="false"
                        label="Use existing provider in finder"
                        name="source"
                        type="radio"
                        @updated="handleCreateNew($event,'create_new_source')"
                    />
                  </div>
                </div>
                <div class="row">
                  <div :class="['col-12', disabledNewSourceStyle]">
                    <div class="d-flex flex-column w-100 mt-4">
                      <TextInput
                          ref="source_name"
                          :value="dataObject?.source?.name"
                          label=""
                          name="source_name"
                          type="text"
                      />
                    </div>
                    <div :class="['reset link-text', disabledNewSourceStyle]" @click="resetName('source_name')">reset
                    </div>
                    <div class="d-flex justify-content-start">
                      <CheckInput
                          ref="defined_benefits"
                          :value="true"
                          label="Defined benefits"
                          name="defined_benefits"
                          type="checkbox"
                      />
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <CheckInput
                          ref="defined_contributions"
                          :checkedVal="true"
                          :value="true"
                          label="Defined contributions"
                          name="defined_contributions"
                          type="checkbox"
                      />
                    </div>
                    <div v-if="externalSource" class="">External source details
                    </div>
                  </div>
                  <div :class="[disabledExistingSourceStyle]" class="col-12">
                    <div class="d-flex flex-column w-100 mt-4">
                      <FuzzySearch
                          ref="fuzzySource"
                          :keys="['name']"
                          :options="getFinderSources"
                          class="fuzzy-source"
                          @selected="handleFuzzySelect($event, 'source')"
                      />
                    </div>
                    <div :class="['reset link-text', disabledExistingSourceStyle]" @click="clearFuzzy('fuzzySource')">
                      clear
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="row">
                  <div class="col-12">
                    <CheckInput
                        :checkedVal="externalEmployer"
                        :value="true"
                        label="Create new employer"
                        name="employer"
                        type="radio"
                        @updated="handleCreateNew($event,'create_new_employer')"
                    />
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <CheckInput
                        :checkedVal="externalEmployer"
                        :value="false"
                        label="Use existing employer in finder"
                        name="employer"
                        type="radio"
                        @updated="handleCreateNew($event,'create_new_employer')"
                    />
                  </div>
                </div>
                <div class="row">
                  <div :class="['col-12', disabledNewEmployerStyle]">
                    <div class="d-flex flex-column w-100 mt-4">
                      <TextInput
                          ref="employer_name"
                          :value="dataObject?.employer?.name"
                          label=""
                          name="employer_name"
                          type="text"
                      />
                    </div>
                    <div class="reset link-text" @click="resetName('employer_name')">
                      reset
                    </div>
                    <div v-if="externalEmployer" class="">External employer details</div>
                  </div>
                  <div :class="[disabledExistingEmployerStyle]" class="col-12">
                    <div class="d-flex flex-column w-100 mt-4">
                      <FuzzySearch
                          ref="fuzzyEmployer"
                          :keys="['name']"
                          :options="getFinderEmployers"
                          class="fuzzy-employer"
                          @selected="handleFuzzySelect($event, 'employer')"
                      />
                    </div>
                    <div class="reset link-text"
                         @click="clearFuzzy('fuzzyEmployer')">clear
                    </div>
                  </div>
                  <div class="col-12 d-flex justify-content-start">
                    <div class="row">
                      <div class="col-12 mt-2 mb-2">
                        <strong>
                          <CheckInput
                              label="Trigger automation event"
                              name="trigger_event"
                              type="checkbox"
                              @updated="handleCreateNew($event,'trigger_event')"
                          />
                        </strong>
                        <p>This will tell finder to emit an outcome event for flow to process.</p>
                      </div>
                      <div class="col-12">
                        <button
                            class="btn btn-small btn-info pointer">Assign
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </template>
  </EditModal>
</template>
<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import {inject, reactive, ref} from 'vue';
import FuzzySearch from "@/components/search/FuzzySearch";
import * as Yup from "yup";

export default {
  name: "FinderKnowledgeBase",
  props: {
    dataObject: {
      type: Object,
      default: () => ({})
    },
    inline: {
      type: Boolean,
      default: true
    },
  },
  data: () => ({
    new_source_id: null,
    new_employer_id: null,
    trigger_event: false,
  }),
  setup() {
    let create_new_source = ref(null);
    let create_new_employer = ref(null);
    let existing_source = ref(null);
    let existing_employer = ref(null);

    let schema = Yup.object().shape({
      dummy: Yup.string(),
      source_name: Yup.string().nullable().when("dummy", {
        is: (value) => create_new_source.value === true,
        then: Yup.string().label('New source name').required().nullable()
      }),
      employer_name: Yup.string().nullable().when("dummy", {
        is: (value) => create_new_employer.value === true,
        then: Yup.string().label('New employer name').required().nullable()
      }),
    });

    const fetchPensionDetails = inject('fetchPensionDetails')

    return {
      schema,
      fetchPensionDetails,
      create_new_source,
      create_new_employer,
      existing_source,
      existing_employer
    };
  },
  computed: {
    ...mapGetters([
      "getFinderEmployers",
      "getFinderSources",
    ]),
    addInline() {
      return {
        '-inline': this.inline
      }
    },
    disabledNewSourceStyle() {
      if (this.create_new_source !== null) {
        return {
          '-disabledStyle': !this.create_new_source
        }
      }
      return {
        '-disabledStyle': !this.externalSource
      }
    },
    disabledExistingSourceStyle() {
      if (this.create_new_source !== null) {
        return {
          '-disabledStyle': this.create_new_source
        }
      }
      return {
        '-disabledStyle': this.externalSource
      }
    },
    disabledNewEmployerStyle() {
      if (this.create_new_employer !== null) {
        return {
          '-disabledStyle': !this.create_new_employer
        }
      }
      return {
        '-disabledStyle': !this.externalEmployer
      }
    },
    disabledExistingEmployerStyle() {
      if (this.create_new_employer !== null) {
        return {
          '-disabledStyle': this.create_new_employer
        }
      }
      return {
        '-disabledStyle': this.externalEmployer
      }
    },
    externalSource() {
      return typeof this.dataObject?.source?.id === 'undefined' || this.dataObject?.source?.id === null;
    },
    externalEmployer() {
      return false; //typeof this.dataObject?.employer?.id === 'undefined' || this.dataObject?.employer?.id === null;
    },
  },
  components: {
    FuzzySearch,
  },
  methods: {
    ...mapMutations([
      "setNotification",
    ]),
    ...mapActions([
      "createSource",
      "createEmployer",
      "quickProviderAssign",
      "getFabricFinderV2Data",
    ]),
    async handleCreateSource() {
      let response = await this.createSource({
        name: this.$refs.source_name.inputValue,
        defined_benefits: this.$refs.defined_benefits.inputValue || false,
        defined_contributions: this.$refs.defined_contributions.inputValue || true,
        type: 1,
        status: 0,
      }).catch(err => {
        console.log(err);
      });

      this.new_source_id = response.data.data.id;
    },
    async handleCreateEmployer() {
      let response = await this.createEmployer({
        name: this.$refs.employer_name.inputValue,
        status: 0,
      }).catch(err => {
        console.log(err);
      });

      this.new_employer_id = response.data.data.id
    },
    handleOpened() {
      this.$nextTick(() => {
        this.$refs.fuzzySource.search = this.dataObject?.source?.name || '';
        this.$refs.fuzzyEmployer.search = this.dataObject?.employer?.name || '';

        this.$nextTick(() => {
          this.scrollToElement('.top');
          if (this.dataObject?.source?.name) {
            this.create_new_source = false;
            let ele1 = document.querySelector('.fuzzy-source .fuzzy-result');
            if (ele1) {
              ele1.click();
            }
          } else {
            this.create_new_source = true;
          }
          if (this.dataObject?.employer?.name) {
            this.create_new_employer = false;
            let ele2 = document.querySelector('.fuzzy-employer .fuzzy-result');
            if (ele2) {
              ele2.click();
            }
          } else {
            this.create_new_employer = false;
          }
        });
      });
    },
    async resetName(name) {
      let key = null;
      switch (name) {
        case 'source_name':
          key = 'source';
          break;
        case 'employer_name':
          key = 'employer';
          break;
      }

      this.$nextTick(() => {
        this.$refs[name].inputValue = this.dataObject[key]?.name;
      });
    },
    clearFuzzy(name) {
      switch (name) {
        case 'fuzzySource':
          this.existing_source = "";
          break;
        case 'fuzzyEmployer':
          this.existing_employer = "";
          break;
      }
      this.$refs[name].search = "";
    },
    handleCreateNew(e, name) {
      switch (name) {
        case 'create_new_source':
          this[name] = e.source;
          break;
        case 'create_new_employer':
          this[name] = e.employer;
          break;
        case 'trigger_event':
          this[name] = e.trigger_event;
          break;
      }
    },
    handleFuzzySelect({result, submitted}, name) {
      if (submitted) {
        this['new_' + name + '_id'] = result.id;
        this['existing_' + name] = result.name;
      }
    },
    async saveForm() {
      if (!confirm('Assign provider to this pension trace?')) {
        return;
      }

      if (this.create_new_source === true) {
        await this.handleCreateSource();
      }
      if (this.create_new_employer === true) {
        await this.handleCreateEmployer();
      }

      let postObject = {
        pensionId: this.$route.params.tpuuid,
        providerId: this.new_source_id || this.dataObject?.source?.id || null,
        employerId: this.new_employer_id || this.dataObject?.employer?.id || null,
        trigger_event: this.trigger_event === true ? 1 : 0
      };

      let response = await this.quickProviderAssign(postObject).catch(err => {
        this.setNotification({
          show: true,
          type: "error",
          message: "Fabric Finder Error!",
          content: this.formulateErrorString(err)
        });
      });
      if (response.status === 200) {
        this.setNotification({
          show: true,
          type: "success",
          message: "Provider added successfully!",
        });
        if (postObject.pensionId !== null) {
          await this.fetchPensionDetails(false);
        }
        this.$refs.finderKnowledgeBase.handleClose();
        if (typeof this.$parent.$parent.$parent.$refs.fabricFinderForm !== 'undefined') {
          this.$parent.$parent.$parent.$refs.fabricFinderForm.handleClose();
        }
      }
    },
  }
}
</script>
<style lang="scss">
.-disabledStyle {
  display: none !important;
  //opacity: 0.3;
  cursor: not-allowed;
  pointer-events: none;
}

.reset {
  position: absolute;
  top: 0;
  right: $spacingBase * 2;
}
</style>