<template>
  <div class="row">
    <div v-if="getEmploymentsCompLength" class="col-12">
      <div class="row">
        <div v-if="showMultipleEmploymentsDropdown" class="col-12">
          <SelectInput
              :options="employmentOptions"
              :with-blank-option="false"
              name="Employments"
              @selected="handleEmploymentSelected"
          />
        </div>
        <div class="col-6">
          <p>
            <strong>ID:</strong><br/>
            {{ selectedEmployment.id }}
          </p>
        </div>
        <div class="col-6">
          <p>
            <strong>Name:</strong><br/>
            {{ selectedEmployment.name_of_employer }}
          </p>
        </div>
        <div class="col-6">
          <p>
            <strong>Industry:</strong><br/>
            {{ selectedEmployment.industry }}
          </p>
        </div>
        <div class="col-6">
          <p>
            <strong>Position:</strong><br/>
            {{ selectedEmployment.position }}
          </p>
        </div>
        <div class="col-6">
          <p>
            <strong>Employment Dates:</strong><br/>
            {{ formatDate(selectedEmployment.employed_from) }} - {{ formatDate(selectedEmployment.employed_to) }}
          </p>
        </div>
        <div class="col-6">
          <p>
            <strong>F-Finder:</strong></p>
          <FinderGroup
              :employment="selectedEmployment"
              :v2-visible="v2Visible"
          />
        </div>
        <div class="col-12">
          <p><strong>PTS:</strong></p>
          <div class="row">
            <div class="col-5">
              <SearchPTS :finder-found="selectedEmployment" class="mb-1"/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="col-12">
      <p>
        <strong>F-Finder:</strong></p>
      <FinderGroup
          :v2-visible="v2Visible"
      />
    </div>
  </div>
</template>
<script>
import {mapGetters, mapActions, mapMutations} from "vuex";
import {useDateFormat} from "@/logic/useDateFormat";
import FinderGroup from "@/components/finder/FinderGroup";
import SearchPTS from "@/components/SearchPTS.vue";

export default {
  name: "EmploymentDetails",
  components: {
    FinderGroup,
    SearchPTS
  },
  props: {
    employmentData: {
      default: null
    }
  },
  emits: ["displayFinder"],
  data: () => ({
    showFabricFinder: false,
    euuid: null,
    empid: null,
    finderVersion: null,
    index: 0,
    v2Visible: 0,
  }),
  setup() {
    const {formatDate} = useDateFormat();
    return {
      formatDate
    };
  },
  computed: {
    ...mapGetters([
      "getEmployments",
      // "getTracedPensionUuid",
    ]),
    getEmploymentsComp() {
      return this.employmentData || this.getEmployments
    },
    getEmploymentsCompLength() {
      return this.getEmploymentsComp.length > 0;
    },
    showMultipleEmploymentsDropdown() {
      return this.getEmploymentsComp.length > 1;
    },
    selectedEmployment() {
      return this.getEmploymentsComp[this.index];
    },
    employmentOptions() {
      return this.getEmploymentsComp.map((x, index) => {
        return {
          id: index,
          text: x.name_of_employer
        }
      })
    }
  },
  methods: {
    ...mapMutations([
      "setFinderStatus",
    ]),
    ...mapActions([
      // "loadTracedPension",
      "getFabricFinderV2Data",
    ]),
    handleEmploymentSelected(value) {
      this.index = value;
    },
    async fetchFinderV2Data() {
      let paramObj = {
        tpuuid: this.$route.params.tpuuid
      }

      let response = await this.getFabricFinderV2Data(paramObj).catch(err => {
        this.v2Visible = 2;
        this.setFinderStatus(false);
      });

      // create mutation here to save results for version 2 table
      if (response?.data?.data?.length > 0) {
        this.v2Visible = 1;
        this.setFinderStatus(true);
      } else if (response?.data?.data?.length === 0) {
        this.v2Visible = 0;
        this.setFinderStatus(true);
      }
    }
  },
  mounted() {
    this.fetchFinderV2Data();
  }
};
</script>
<style lang="scss" scoped>

</style>
