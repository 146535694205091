<template>
  <div class="card h-100">
    <div class="card-body h-100 text-left">
      <h5 class="card-title">Pending Trace Events</h5>
      <table class="table table-hover text-left">
        <thead>
        <tr>
          <!--          <th scope="col">ID</th>-->
          <th scope="col">Trace ID</th>
          <th scope="col">Outcome</th>
          <th scope="col" style="width:200px;"></th>
        </tr>
        </thead>
        <tbody>
        <tr
            v-for="(e, index) in eventsComputed"
            :key="index"
            class="table-row"
        >
          <td>
            <span class="text-subdued" @click="handleSelect(e)">{{ e.event_id }}</span>
          </td>
          <td>
            <span v-html="formatOutcome(e.payload?.event_payload)"></span>
          </td>
          <td>
            <button class="btn btn-sm btn-success mb-1" data-v-7e83fcbc="" name="button" type="button"
                    @click="handleApply(e)">Apply
            </button>
            &nbsp;&nbsp;
            <button class="btn btn-sm btn-danger mb-1" data-v-7e83fcbc="" name="button" type="button"
                    @click="handleDecline(e)">Decline
            </button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
  name: "EventList",
  props: {
    events: {
      type: Object,
      default: () => ({})
    },
    allEvents: {
      type: Boolean,
      default: false
    },
  },
  data: () => ({
    updatedEvents: null
  }),
  computed: {
    ...mapGetters([
      "getTracedPension"
    ]),
    eventsComputed() {
      return this.updatedEvents || this.events;
    }
  },
  methods: {
    ...mapActions([
      "setTracedPension",
      "actionEvent",
      "declineEvent",
      "getFailedEvents",
    ]),
    ...mapMutations([
      "setNotification",
      "setTracedPension",
    ]),
    formatOutcome({reason_text, ops_reason, loas}) {
      if (!reason_text) {
        text = "<span class='text-color-red text-size-16'>No match.</span><br>";
        text += "Aviva unable to match trace";
        return text;

      }
      let text = "";
      switch (reason_text) {
        case 'matched':
          text = "<span class='text-color-green text-size-16'>Trace match found.</span><br>";
          text += "LOA(s) can be generated for the following providers:<br>";
          for (let i in loas) {
            text += "<strong>" + loas[i].name + "<br></strong>";
          }
          break;
        case 'rejected':
        case 'not-found':
        case 'not found':
        case 'no-pension':
        case 'no pension':
        case 'not-matched':
        case 'not matched':
          text = "<span class='text-color-red text-size-16'>Trace rejected.</span><br>";
          text += "This trace has been rejected for the following reason: <br><strong>" + ops_reason + "</strong>";
          break;
      }
      return text;
    },
    getActionText(value) {
      switch (value) {
        case '0':
          return 'Processed'
        case '1':
          return 'Not processed'
      }
    },
    async handleDecline(params) {
      if (!confirm("Are you sure you wish to decline this event?")) {
        return;
      }
      let response = await this.declineEvent({
        id: params.id,
        tpuuid: this.$route.params.tpuuid
      }).catch(err => console.log(err));

      if (response.status === 200) {
        this.setNotification({
          show: true,
          type: "success",
          message: "Event has been declined!"
        });
        this.setTracedPension(response.data.data.trace);
        this.updatedEvents = response.data.data.trace.event_caching;
      }
    },
    async handleApply(params) {
      if (this.getTracedPension.status === 'complete') {
        if (!confirm("This trace has been completed, are you sure you wish to proceed?")) {
          return;
        }
      }
      let response = await this.actionEvent({
        id: params.id,
        tpuuid: this.$route.params.tpuuid
      }).catch(err => {
        this.setNotification({
          show: true,
          type: "error",
          message: "Something went wrong!",
          content: this.formulateErrorString(err)
        });
      });

      if (response.status === 200) {
        this.setNotification({
          show: true,
          type: "success",
          message: "Event is processing..."
        });
        if (this.allEvents === false) {
          this.setTracedPension(response.data.data.trace);
          this.updatedEvents = response.data.data.trace.event_caching;
        } else {
          let response = await this.getFailedEvents().catch(err => console.log(err));
          if (response.status === 200) {
            this.updatedEvents = response.data.data.events;
          }
        }
      }
    }
  },
}
</script>
<style lang="scss"></style>