<template>
  <button @click.prevent="handlePTS(finderFound.name_of_employer)" class="btn btn-sm btn-success" type="button" name="button">
    Search
  </button>
</template>
<script>
export default {
  name: "SearchPTS",
  props: {
    finderFound: {
      type: Object
    },
  },
  computed: {
    findpensioncontactsUrl() {
      return `${process.env.VUE_APP_PTS_URL_1}${this.nameOfEmployer}${process.env.VUE_APP_PTS_URL_1_PAGE}`;
    },
    findUpdateUrl() {
      return `${process.env.VUE_APP_PTS_URL_2}${this.nameOfEmployer}`;
    },
  },
  methods: {
    handlePTS(name_of_employer) {
      this.nameOfEmployer = encodeURIComponent(name_of_employer);
      window.open(this.findpensioncontactsUrl, 'w1');
      setTimeout(() => {
        window.open(this.findUpdateUrl, 'w2');
      }, 500);
    },
  },
}
</script>