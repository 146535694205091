<template>
  <button v-if="task.show" type="button" class="btn btn-sm btn-success" :disabled="task.disabled"
    @click.prevent="handleAction(task.type)">
    Generate LOA
  </button>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  name: "GenerateLOA",
  props: {
    uuid: {
      type: String,
      default: null
    },
    tasks: {
      type: Array,
      default: null
    }
  },
  emits: ["handle-action"],
  data: () => ({
    task: {
      type: "create-loa",
      name: "Create Letter of Authority",
      actioned: "No",
      disabled: false,
      show: true
    }
  }),
  watch: {
    tasks(newTasks, oldTasks) {
      if (newTasks) this.updateTasks(newTasks);
    },
    getPusherNotification(param) {
      switch(param.type) {
        case 'create-loa':
          this.task.disabled = false;
          break;
      }
    }
  },
  computed: {
    ...mapGetters(['getPusherNotification'])
  },
  methods: {
    ...mapMutations(["setLoading", "setNotification"]),

    ...mapActions(["attachTask"]),

    async handleAction(type) {
      let uuid = this.uuid;
      this.task.disabled = true;
      this.task.actioned = "Creating task";

      await this.attachTask({
        uuid,
        task: this.task.type
      })
        .then(response => {
          this.task.actioned = "Committing action";
          this.setNotification({
            show: true,
            type: "success",
            message: "Task added successfully!"
          });
          this.$emit("handle-action", { tp_uuid: uuid, type:this.task.type });
        })
        .catch(err => {
          this.task.disabled = false;
          this.task.actioned = "No";
          this.setNotification({
            show: true,
            type: "error",
            message: err.response.data.error + "!"
          });
        });
    },
    updateTasks(tasks) {
      //  TODO:   this is a hack!  There is no way to tell if a task has been attached but not actioned
      //          and no tasks are received if a LOA is yet to be created.  So the LOA button is available
      //          by default and turned off if not required  This may be from it already being actioned
      //          or it could be refreshed during a session but still not be listed as actioned === 1
      tasks.forEach(task => {
        if (this.task.type === task.type) {
          let item = this.task;
          // item.disabled = false;
          item.show = true;
          item.actioned = task.actioned === "0" ? "In progress" : "Yes";
        }
      });
    }
  }
};
</script>
