<template>
  <EditModal ref="fabricFinderForm" class="-maximise-width -overflow-visible">
    <template #btn-text></template>
    <template #formTitle></template>
    <template #form>
      <h4 class="mb-3">
        <slot name="formTitle">Fabric Finder {{ finderVersion.toUpperCase() }}</slot>
      </h4>
      <div v-if="this.processingData" class="blink loading-text">Loading...</div>
      <div v-else>
        <div v-if="getFinderResultLength && finderVersion === 'v2'" class="table-responsive">
          <div v-for="(finderFound, index) in getFinderResultData" :key="index"
               class="card bg-light mb-3 -inline-card d-inline-flex"
               style="max-width: 25rem;">
            <div class="card-body -no-shadow d-flex" style="flex-grow: 1;">
              <div class="row" style="flex-grow: 1;">
                <div class="col-12">
                  <strong>Suggestion type: <span class="badge badge-grey ml-1 mb-1">{{
                      capitalizeFirstLetter(finderFound?.typename)
                    }}</span></strong>
                  <hr>
                </div>
                <div class="col-12">
                  <div class="d-flex flex-row justify-content-between mb-2">
                    <div class="d-flex flex-row">
                      <icon :data="employment" class="bg-transparent text-secondary pl-1" dir="up" height="26"
                            style="flex: none;" width="26"/>
                      <div class="d-flex flex-column ml-2">
                        <div v-if="finderFound?.employer === null">
                          <span class="text-color-grey text-size-16">
                            <strong>Employer not found</strong>
                          </span>
                        </div>
                        <div v-else>
                          <h5 class="card-title d-flex justify-content-between text-color-orange"
                              style="padding: 0;margin: 0!important;">
                            <strong>{{ finderFound?.employer?.name }}</strong>
                          </h5>
                          <span><strong>{{ (getDuration(finderFound?.employer)) }}</strong></span>
                          <p class="mt-2">
                            Employer Score:
                            <span
                                v-if="finderFound?.employer?.reasons?.length === 0"><strong>{{
                                finderFound?.employer?.score
                              }}</strong></span>
                            <PopOver v-else :content="parseReason(finderFound?.employer?.reasons)" class="-width-400"
                                     style="display: inline;">
                              <span class="pointer"><strong>{{ finderFound?.employer?.score }}</strong></span>
                            </PopOver>
                            <br>
                            Industry: <strong><span>{{
                              getLast(finderFound?.employer?.industries)?.name
                            }}</span></strong>
                            <br>
                            <span v-if="parseReason(finderFound?.employer?.reasons) !== ''">
                            Reasons: <br><strong v-html="parseReason(finderFound?.employer?.reasons)"></strong>
                          </span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div v-if="finderFound?.status === 1">
                      <icon :data="tick" color="#28a745" dir="up" height="20" width="20"/>
                    </div>
                    <div v-else-if="finderFound?.status === 2">
                      <icon :data="cross" color="#c82333" dir="up" height="20" width="20"/>
                    </div>
                  </div>
                  <hr>
                  <div class="d-flex flex-row">
                    <icon :data="provider" class="bg-transparent text-secondary pl-1" dir="up" height="20"
                          style="flex: none;"
                          width="20"/>
                    <div class="ml-2 mb-2 w-100">
                      <h5 class="card-title" style="padding: 0;margin: 0!important;">
                        <div v-if="finderFound?.source === null">
                          <span class="text-color-grey text-size-16">
                            <strong>Provider not found</strong>
                          </span>
                        </div>
                        <PopOver v-else-if="finderFound?.source?.parent !== null"
                                 :content="finderFound?.source?.parent?.name">
                          <span class="text-color-dark-blue text-size-16 pointer">
                            <strong>{{ finderFound?.source?.name }} <i class="">(scheme)</i></strong>
                          </span>
                        </PopOver>
                        <div v-else-if="finderFound?.source?.parent === null">
                          <span class="text-size-16 text-color-dark-blue">
                            <strong>{{ finderFound?.source?.name }}</strong>
                          </span>
                        </div>
                        <div v-else>Provider not set</div>
                      </h5>
                      <span><strong>{{ (getDuration(finderFound?.source)) }}</strong></span>
                      <div v-if="finderFound?.source !== null" class="row mt-2">
                        <div class="col-12">
                          <p class="card-text">
                            <span class="d-inline-flex">
                              Provider Score:&nbsp;
                              <span class="pointer"><strong>{{ finderFound?.source?.score }}</strong></span>
                            </span>
                            <br>
                            <span v-if="parseReason(finderFound?.source?.reasons) !== ''">
                              Reasons: <br><strong v-html="parseReason(finderFound?.source?.reasons)"></strong>
                            </span>
                          </p>
                        </div>
                      </div>
                      <div class="row mt-2">
                        <div class="col-12" v-html="parseStats(finderFound?.source?.traces)">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <hr>
                </div>
                <div v-if="finderFound?.typename === 'fabric'"
                     class="col-12 d-flex justify-content-end align-items-end">
                  <FinderKnowledgeBase
                      :data-object="finderFound"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="mt-3">No records found!</div>
      </div>
    </template>
  </EditModal>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import employment from "@fa/solid/suitcase.svg";
import provider from "@fa/solid/share-alt.svg";
import tick from "@fa/solid/check-square.svg";
import cross from "@fa/solid/window-close.svg";
import FinderKnowledgeBase from "@/components/finder/FinderKnowledgeBase.vue";

export default {
  name: "FabricFinderModal",
  props: {
    tpid: {
      type: String,
      default: null
    },
    euuid: {
      type: String,
      default: null
    },
    empid: {
      type: String,
      default: null
    },
    isShown: {
      type: Boolean,
      default: false
    },
    finderVersion: {
      type: String,
      default: 'vi'
    },
  },
  data: () => ({
    processingData: false,
    finderResultData: null,
    nameOfEmployer: null,
    employment,
    provider,
    tick,
    cross,
  }),
  components: {
    FinderKnowledgeBase
  },
  watch: {
    isShown: function () {
      if (this.isShown) {
        this.processingData = true;
        this.loadFinderResults();
        this.$refs.fabricFinderForm.handleClick();
      }
    }
  },
  computed: {
    ...mapGetters([
      "getFabricFinderResults",
      "getTracedPensionUuid",
      "getTracedPension",
    ]),
    getFinderResultLength() {  // TODO: display result from finder v2
      switch (this.finderVersion) {
        case 'v1':
          return this.finderResultData.length;
        case 'v2':
          return this.finderResultData.data.length;
      }
      return [];
    },
    getFinderResultData() {
      return this.finderResultData.data || this.finderResultData;
    },
  },
  methods: {
    ...mapMutations(['setNotification']),
    ...mapActions([
      "getFabricFinderData",
      "getFabricFinderV2Data",
      "quickProviderAssign",
      // "fetchFinderEmploymentList",
      // "fetchFinderSourceList",
    ]),
    getDuration(obj) {
      if (!obj) return '';
      if (!obj?.dates?.earliest || !obj?.dates?.latest) return '';
      return `${(this.formatReadDate(obj.dates.earliest) || '')} - ${(this.formatReadDate(obj.dates.latest) || '')}`;
    },
    parseReason(arr) {
      if (!arr) return '';
      let temp = arr.map(i => `&#8226;&nbsp;${this.capitalizeFirstLetter(i.reason)}`);
      return temp.join('<br>');
    },
    parseStats(obj) {
      if (!obj) return '';
      let temp = '';
      for (const [key, value] of Object.entries(obj)) {
        temp += `<span class="badge badge-grey ml-1 mb-1">${this.deCamel(key)}: <strong>${value}</strong></span>`;
      }
      return temp;
    },
    getStatus(int, type = null) {
      if (type === 'class') {
        switch (int) {
          case 0:
            return 'badge-info';
          case 1:
            return 'badge-secondary';
          case 2:
            return 'badge-danger';
        }
      }
      switch (int) {
        case 0:
          return 'New';
        case 1:
          return 'Accepted';
        case 2:
          return 'Rejected';
      }
    },
    async handleAutoAssign(params) {
      if (!confirm('Assign provider to this pension trace?')) {
        return;
      }

      params.employmentId = this.empid;
      if (this.$route.name === 'Application Focus') {
        params.pensionId = null;
      } else {
        params.pensionId = this.getTracedPensionUuid;
      }

      let response = await this.quickProviderAssign(params).catch(err => {
        this.setNotification({
          show: true,
          type: "error",
          message: "Fabric Finder Error!",
          content: this.formulateErrorString(err)
        });
      });
      if (response.status === 200) {
        this.setNotification({
          show: true,
          type: "success",
          message: "Provider added successfully!",
        });
        if (params.pensionId !== null) {
          this.$parent.$parent.fetchPensionDetails();
        }
      }
    },
    async loadFinderResults() {
      let actionName, paramObj;

      switch (this.finderVersion) {
        case 'v1':
          actionName = 'getFabricFinderData';
          paramObj = {
            euuid: this.euuid
          }
          break;
        case 'v2':
          actionName = 'getFabricFinderV2Data';
          paramObj = {
            tpuuid: this.getTracedPension?.uuid
          }
          break;
      }

      let fFinderResponse = await this[actionName](paramObj).catch(err => {
        this.processingData = false;
        this.$refs.fabricFinderForm.handleClose();
        this.setNotification({
          show: true,
          type: "error",
          message: "Fabric Finder Error!",
          content: this.formulateErrorString(err)
        });
      });
      this.processingData = false;
      this.finderResultData = fFinderResponse.data;
    }
  },
  // async beforeMount() {
  //   let finderEmployers = await this.fetchFinderEmploymentList().catch(err => console.log);
  //   let finderSource = await this.fetchFinderSourceList().catch(err => console.log);
  //   await Promise.all([finderEmployers, finderSource]);
  // },
};
</script>
<style lang="scss">
.btn-secondary {
  background-color: lighten(grey, 30%);

  &:hover {
    background-color: #007bff;
    border-color: #007bff;
  }
}

.badge {
  &-grey {
    background-color: #cdcdcd;
  }
}
</style>
