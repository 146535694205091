<template>
  <div>
    <div>
      <!-- <div v-if="employment.has_provider_suggestion === true" class="d-inline-block">
        <PopOver content="Finder V1">
          <icon
              :data="finder"
              class="icon"
              height="20"
              role="button"
              width="20"
              @click.stop="handleFinder(employment.uuid, employment.id, 'v1')"
          />
        </PopOver>
      </div> -->
      <div v-if="$route.name === 'Traced Pension'" class="d-inline-block">
        <PopOver v-if="v2Visible === 0" content="Finder&nbsp;V2&nbsp;-&nbsp;no&nbsp;results">
          <icon
              :data="finder"
              class="icon -v2-no-result"
              height="20"
              role="button"
              width="20"
          />
        </PopOver>
        <PopOver v-else-if="v2Visible === 1" content="Finder V2">
          <icon
              :data="finder"
              class="icon -v2"
              height="20"
              role="button"
              width="20"
              @click.stop="handleFinder(employment.uuid, employment.id, 'v2')"
          />
        </PopOver>
        <PopOver v-else-if="v2Visible === 2"
                 content="Record&nbsp;not&nbsp;found!<br>Click&nbsp;to&nbsp;add&nbsp;employment...">
          <icon
              :data="finder"
              class="icon -v2-404-not-found"
              height="20"
              role="button"
              width="20"
              @click.stop="handleSendToFinder(employment)"
          />
        </PopOver>
      </div>
    </div>
    <FabricFinderModal
        ref="fabricFinder"
        :empid="empid"
        :euuid="euuid"
        :finder-version="finderVersion"
        :isShown="showFabricFinder"
        @closed="handleClose"
    />
  </div>
</template>
<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import finder from "@fa/solid/fingerprint.svg";
import FabricFinderModal from "@/components/finder/FabricFinderModal";

export default {
  name: "FinderGroup",
  props: {
    employment: {
      type: Object,
      default: () => ({})
    },
    v2Visible: {
      type: Number,
      default: 0
    },
  },
  components: {
    FabricFinderModal,
  },
  data: () => ({
    finder,
    showFabricFinder: false,
    euuid: null,
    empid: null,
    finderVersion: null,
    // v2Visible: 0,
  }),
  computed: {
    ...mapGetters([
      "getTracedPensionUuid",
      "getTracedPension",
    ]),
  },
  methods: {
    ...mapMutations([
      'setNotification',
      'setFinderStatus',
    ]),
    ...mapActions([
      "getFabricFinderV2Data",
      "postFabricFinderV2Data",
    ]),
    async handleSendToFinder(employment) {
      let response = await this.postFabricFinderV2Data({
        'uuid': this.$route.params.tpuuid,
        'user_supplied_provider_name': this.getTracedPension?.user_supplied_provider_name,
        'employer_name': employment?.name_of_employer,
        'employer_industry': employment?.industry,
        'employment_position': employment?.position,
        'employment_start_date': employment?.employed_from,
        'employment_end_date': employment?.employed_to
      }).catch(err => {
        this.setNotification({
          show: true,
          type: "error",
          message: err.response.data.error
        });
      });

      if (response.data.success === true) {
        this.setFinderStatus(false);
        this.$parent.$parent.fetchPensionDetails();
        this.$parent.fetchFinderV2Data();
        this.setNotification({
          show: true,
          type: "success",
          message: "Finder V2 request submitted!"
        });
      }
    },
    handleFinder(euuid, empid, version) {
      this.showFabricFinder = true;
      this.euuid = euuid;
      this.empid = empid;
      this.finderVersion = version
    },
    handleClose() {
      this.euuid = null;
      this.showFabricFinder = false;
    },
    // async fetchFinderV2Data() {
    //   let paramObj = {
    //     tpuuid: this.getTracedPensionUuid
    //   }
    //
    //   let response = await this.getFabricFinderV2Data(paramObj).catch(err => {
    //     this.v2Visible = 2;
    //     this.setFinderStatus(false);
    //   });
    //
    //   // create mutation here to save results for version 2 table
    //   if (response?.data?.data?.length > 0) {
    //     this.v2Visible = 1;
    //     this.setFinderStatus(true);
    //   } else if (response?.data?.data?.length === 0) {
    //     this.v2Visible = 0;
    //     this.setFinderStatus(true);
    //   }
    // }
  },
  // mounted() {
  // this.fetchFinderV2Data();
  // }
}
</script>
<style lang="scss" scoped>
.icon {
  color: green;

  &.-v2 {
    color: blue;
    //margin-left: 10px;
  }

  &.-v2-404-not-found {
    color: red;
    //margin-left: 10px;
  }

  &.-v2-no-result {
    color: grey;
    opacity: 0.5;
    //margin-left: 10px;
  }
}
</style>