<template>
  <div class="TextInput">
    <input
        id="searchBox"
        :ref="ref"
        v-model="search"
        autocomplete="off"
        type="text"
        @keydown="handleKeydown"
    />
    <p v-if="noResults">Sorry, no results for {{ search }}</p>
    <div v-if="showResult">
      <div
          v-for="(r, i) in results"
          :key="i"
          ref="fuzzyResult"
          class="fuzzy-result"
          @click="handleSelect(r, i)"
      >
        <span>{{ r.name }}</span>
        <span v-html="r.owner"/>
      </div>
    </div>
  </div>
</template>
<script>
import {defineComponent} from "vue";
import {useVueFuse} from "vue-fuse";
import {toRefs} from "vue";

export default defineComponent({
  props: {
    options: {
      default: null
    },
    keys: {
      default: null
    },
    ref: {
      type: String,
      default: 'fuzzySearch'
    }
  },
  emits: ["selected"],
  data: () => ({
    showResult: true
  }),
  setup(props) {
    let {options, keys} = toRefs(props);
    let opts = {
      keys: keys.value
    };
    const {search, results, noResults} = useVueFuse(options.value, opts);

    return {
      search,
      results,
      noResults
    };
  },
  watch: {
    value(n) {
      this.search = n;
    }
  },
  methods: {
    handleKeydown(e) {
      this.showResult = true;
      this.$emit("selected", {name: e.target.value, submitted: false});
    },
    handleSelect(result) {
      this.showResult = false;
      this.search = result.name;
      this.$emit("selected", {result, submitted: true});
    }
  },
});
</script>
<style lang="scss" scoped>
.fuzzy-result {
  width: 100%;
  background-color: lighten(grey, 45%);
  cursor: pointer;
  padding: calc($spacingBase / 2);
  display: flex;
  justify-content: space-between;

  &:first-child {
    border-top-left-radius: $borderRadius;
    border-top-right-radius: $borderRadius;
  }

  &:last-child {
    border-bottom-left-radius: $borderRadius;
    border-bottom-right-radius: $borderRadius;
  }

  &:hover {
    background-color: lighten(grey, 20%);
    color: white;
  }
}
</style>
